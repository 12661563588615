import Vue from 'vue'
import Vuex from 'vuex'
import * as user from './user.js';
import * as club from './club.js';
import * as team from './team.js';
import * as clubs from './clubs.js';
import * as media from './media.js';
import * as mission from './mission.js';
import * as content from './content.js';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		user,
		club,
		team,
		clubs,
		media,
		mission,
		content,
	}
})
