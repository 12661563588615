import Vue from 'vue';
import { http } from '@/plugins/http';

export const namespaced = true;

let fetchBatch = [];
let fetchBatchTimer;

export const state = () => {
	const mediaData = localStorage.getItem('media');
	const storedMedia = mediaData ? JSON.parse(mediaData) : {};
	return { 
		media: storedMedia,
	};
};

export const getters = {
	mediaSrcById: (state) => (mediaId) => {
		return state.media[mediaId];
	},
	mediaUrlById: (state) => (mediaId, conversion = null) => {
		if(conversion) {
			return state.media[mediaId]?.conversion_urls[conversion];
		}
		return state.media[mediaId]?.url;
	},
};

export const mutations = {
	setMediaItem(state, data) {
		Vue.set(state.media, data.media_id, { ...data.media });
	},
	setMediaItems(state, data) {
		data.forEach((item) => {
			Vue.set(state.media, item.id, { ...item });
		});
	},
	setMedia(state, data) {
		state.media = {...data};
	},
	clearAll(state) {
		state.media = {};
	},
	clearByIds(state, ids) {
		ids.forEach((id) => {
			Vue.set(state.media, id, undefined);
			delete state.media[id];
		});
	},
	clearById(state, id) {
		Vue.set(state.media, id, undefined);
		delete state.media[id];
	},
};

export const actions = {
	async storeMediaItem({ commit, state }, data) {
		commit('setMediaItem', data);
		localStorage.setItem('media', JSON.stringify(state.media));
	},
	async storeMediaItems({ commit, state }, data) {
		commit('setMediaItems', data);
		localStorage.setItem('media', JSON.stringify(state.media));
	},
	async clearAll({ commit }) {
		commit('clearAll');
	},
	async fetchMediaDebounced({ dispatch }, { mediaId }) {

		clearTimeout(fetchBatchTimer);

		fetchBatch.push(mediaId);

		fetchBatchTimer = setTimeout(() => {
			dispatch('fetchMediaBatch', { media: fetchBatch });
			fetchBatch = [];
		}, 500);

	},
	async fetchMediaBatch({ dispatch }, { media }) {
		try {
			const response = await http.post('/media', { media });
			await dispatch('storeMediaItems', response.data.data.media);
		} catch(e) {
			return Promise.reject(e);
		}
	},
	async fetchMedia({ dispatch }, { mediaId }) {
		try {
			const response = await http.post(`/media/${mediaId}`);
			await dispatch('storeMediaItem', {
				media_id: mediaId,
				media: response.data.data.media,
			});
		} catch(e) {
			return Promise.reject(e);
		}
	},
};