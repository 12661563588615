import router from '@/router';

export default {
	methods: {
		handleFormErrorFromServer(e) {
			if(e.response && e.response.data && e.response.data.errors && this.$refs.formValidator) {
				this.$refs.formValidator.setErrors(e.response.data.errors);
				return;
			}

			let message = e.response?.data?.data?.error;

			if(!message) {
				message = e.response?.data?.error;
			}
			if(!message) {
				message = e.response?.data?.data?.message;
			}
			if(!message) {
				message = e.response?.data?.message;
			}
			if(!message) {
				message = e.message;
			}
			if(!message) {
				message = 'An error occurred';
			}

			this.$buefy.dialog.alert({
				type: 'is-danger',
				message,
			});

		},
		handleResourceFetchError(e) {
			switch(e.response.status) {
				case 403:
				case 404:
				case 500:
					router.push(`/error/${e.response.status}`);
				break;
			}
		},
		getMessageForServerError(e) {
			let message = e.response?.data?.data?.error;

			if(!message) {
				message = e.response?.data?.error;
			}
			if(!message) {
				message = e.response?.data?.data?.message;
			}
			if(!message) {
				message = e.response?.data?.message;
			}
			if(!message) {
				message = e.message;
			}
			if(!message) {
				message = 'An error occurred';
			}

			return message;
		},
		showAlertForServerError(e) {

			let message = e.response?.data?.data?.error;

			if(!message) {
				message = e.response?.data?.error;
			}
			if(!message) {
				message = e.response?.data?.data?.message;
			}
			if(!message) {
				message = e.response?.data?.message;
			}
			if(!message) {
				message = e.message;
			}
			if(!message) {
				message = 'An error occurred';
			}

			this.$buefy.dialog.alert({
				type: 'is-danger',
				message,
			});
		},
	},
}