<template>
	<validation-observer ref="formValidator" v-slot="{ passes }">
		<form @submit.prevent="passes(submit)" class="modal-card">
			<header class="modal-card-head">
				<p class="modal-card-title">Edit profile</p>
				<button class="delete" type="button" aria-label="close" @click="close"></button>
			</header>
			<section class="modal-card-body">

				<b-field grouped>
					<validation-provider slim v-slot="{ errors }" name="first_name" rules="required">
						<b-field
							expanded
							label="First name"
							:message="errors"
							:type="{ 'is-danger': errors[0] }"
						>
							<b-input v-model="formData.first_name" required></b-input>
						</b-field>
					</validation-provider>

					<validation-provider slim v-slot="{ errors }" name="last_name" rules="required">
						<b-field
							expanded
							label="Surname"
							:message="errors"
							:type="{ 'is-danger': errors[0] }"
						>
							<b-input v-model="formData.last_name" required></b-input>
						</b-field>
					</validation-provider>
				</b-field>


				<validation-provider slim v-slot="{ errors }" name="email" rules="required|email">
					<b-field
						label="Email"
						:message="errors"
						:type="{ 'is-danger': errors[0] }"
					>
						<b-input v-model="formData.email" required></b-input>
					</b-field>
				</validation-provider>

				<b-field
					v-if="user.profile_picture"
					grouped
					label="Profile picture"
				>
					<div>
						<div>
							<b-button type="is-primary" @click="openImageModal(user.profile_picture)">View current profile picture</b-button>
						</div>
						<div class="flex items-center mt-4">

							<b-field>
								<b-checkbox v-model="changeProfilePicture">
									Change profile picture
								</b-checkbox>
							</b-field>
							<b-field>
								<b-checkbox v-model="removeProfilePicture">
									Remove profile picture
								</b-checkbox>
							</b-field>
						</div>
					</div>
				</b-field>

				<validation-provider
					v-if="!user.profile_picture || changeProfilePicture"
					slim
					v-slot="{ errors }"
					name="profile_picture"
					rules="mimes:image/*|size:300000"
				>
					<b-field
						label="Profile picture"
						:message="errors"
						:type="{ 'is-danger': errors[0] }"
					>
						<b-upload v-model="formData.profile_picture" accept="image/*" drag-drop expanded>
							<section class="section">
								<div class="content has-text-centered">
									<p>
										<b-icon icon="upload" size="is-large"></b-icon>
									</p>
									<p v-if="formData.profile_picture">Selected {{formData.profile_picture.name}}</p>
									<p v-else>Drop your file here or click to upload</p>
								</div>
							</section>
						</b-upload>
					</b-field>
				</validation-provider>

			</section>
			<footer class="modal-card-foot">
				<div class="flex w-full">
					<div>
						<b-button @click="close">Cancel</b-button>
					</div>
					<div class="ml-auto">
						<b-button type="is-primary" native-type="submit">Save changes</b-button>
					</div>
				</div>
			</footer>
		</form>
	</validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import handlesErrors from '@/mixins/handles-errors.js';
import { mapState } from 'vuex';
import ImageModal from '@/components/modals/ImageModal.vue';

export default {
	name: 'PlayerEditModal',
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	mixins: [ handlesErrors ],
	props: {
	},
	data() {
		return {
			formData: {
				first_name: this.$store.state.user.user.first_name,
				last_name: this.$store.state.user.user.last_name,
				email: this.$store.state.user.user.email,
				profile_picture: null,
			},
			changeProfilePicture: false,
			removeProfilePicture: false,
		};
	},
	computed: {
		...mapState('user', ['user']),
	},
	watch: {
		changeProfilePicture(val) {
			if(val) {
				this.removeProfilePicture = false;
			}
		},
		removeProfilePicture(val) {
			if(val) {
				this.changeProfilePicture = false;
			}
		},
	},
	methods: {
		close() {
			this.$parent.close();
		},
		async submit() {
			const loader = this.$buefy.loading.open();

			let formData = new FormData();

			formData.append('first_name', this.formData.first_name);
			formData.append('last_name', this.formData.last_name);
			formData.append('email', this.formData.email);

			if(this.formData.profile_picture) {
				formData.append('profile_picture', this.formData.profile_picture);
			}
			if(this.removeProfilePicture) {
				formData.append('remove_profile_picture', '1');
			}

			try {

				await this.$http.post('/me/profile', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					},
				});

				// If user has uploaded a profile picture, clear the old one from the store
				if(
					this.user.profile_picture &&
					(
						this.formData.profile_picture ||
						this.removeProfilePicture
					)
				) {
					await this.$store.commit('media/clearById', this.user.profile_picture.id);
				}

				await this.$store.dispatch('user/fetchUser');

				this.$buefy.snackbar.open('Profile updated');

				this.$parent.close();

			} catch(e) {
				this.handleFormErrorFromServer(e);
				this.$buefy.snackbar.open({
					message: 'Could not update profile',
					type: 'is-danger',
				});
			}

			loader.close();
		},
		openImageModal(media) {
			this.$buefy.modal.open({
				parent: this,
				component: ImageModal,
				trapFocus: true,
				canCancel: ['escape', 'x', 'outside'],
				props: {
					media: media,
				},
			});
		},
	},
}
</script>
