<template>
	<validation-observer ref="formValidator" v-slot="{ passes }">
		<form @submit.prevent="passes(submit)" class="modal-card">
			<header class="modal-card-head">
				<p class="modal-card-title">Change password</p>
				<button class="delete" type="button" aria-label="close" @click="close"></button>
			</header>
			<section class="modal-card-body">
			
				<validation-provider slim v-slot="{ errors }" name="current_password" rules="required">
					<b-field 
						label="Current password"
						:message="errors" 
						:type="{ 'is-danger': errors[0] }"
					>
						<b-input ref="password" v-model="formData.current_password" type="password" />
					</b-field>
				</validation-provider>

				<validation-provider slim v-slot="{ errors }" name="password" rules="required|min:8|confirmed:password_confirmation">
					<b-field 
						label="New password"
						:message="errors" 
						:type="{ 'is-danger': errors[0] }"
					>
						<b-input ref="password" v-model="formData.password" type="password" />
					</b-field>
				</validation-provider>

				<validation-provider slim v-slot="{ errors }" name="password_confirmation" rules="required">
					<b-field 
						label="Confirm new password"
						:message="errors" 
						:type="{ 'is-danger': errors[0] }"
					>
						<b-input v-model="formData.password_confirmation" type="password" />
					</b-field>
				</validation-provider>
					
			</section>
			<footer class="modal-card-foot">
				<div class="flex w-full">
					<div>
						<b-button @click="close">Cancel</b-button>
					</div>
					<div class="ml-auto">
						<b-button type="is-primary" native-type="submit">Save changes</b-button>
					</div>
				</div>
			</footer>
		</form>
	</validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import handlesErrors from '@/mixins/handles-errors.js';
//import { bus } from '@/plugins/event-bus';

export default {
	name: 'PlayerEditModal',
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	mixins: [ handlesErrors ],
	data() {
		return {
			formData: {
				current_password: '',
				new_password: '',
				new_password_confirmation: '',
			},
		};
	},
	methods: {
		close() {
			this.$parent.close();
		},
		async submit() {
			const loader = this.$buefy.loading.open();

			try {
			
				await this.$http.post('/me/change-password', {
					...this.formData,
				});

				this.$buefy.snackbar.open('Password changed');
			

				this.$parent.close();

			} catch(e) {
				this.handleFormErrorFromServer(e);
				this.$buefy.snackbar.open({
					message: 'Could not changed password',
					type: 'is-danger',
				});
			}

			loader.close();
		},
	},
}
</script>