import { forOwn, isEmpty } from 'lodash';
import { http } from '@/plugins/http';

export const namespaced = true;

export const state = () => {
	return {
		content: {},
	};
};

export const mutations = {
	setContent(state, data) {
		forOwn(data, (value, key) => {
			state.content[key] = value;
		})
	},
	clearAll(state) {
		state.content = {};
	},
};

export const getters = {
	value: (state) => (key) => {
		return state.content[key];
	},
};

export const actions = {
	async init({ commit, dispatch }) {
		const contentData = await localStorage.getItem('content');
		const storedContent = contentData ? JSON.parse(contentData) : {};
		console.log('init content', storedContent)
		if(storedContent && !isEmpty(storedContent)) {
			commit('setContent', storedContent);
		} else {
			await dispatch('fetchContent');
		}
	},
	async storeContent({ commit, state }, data) {
		commit('setContent', data);
		return await localStorage.setItem('content', JSON.stringify(state.content));
	},
	async clearAll({ commit }) {
		commit('clearAll');
	},
	async fetchContent({ dispatch }) {
		try {
			const response = await http.get('/content');
			await dispatch('storeContent', response.data.data);
		} catch(e) {
			return Promise.reject(e);
		}
	},
};
