import axios from 'axios';
import store from '@/store';
import router from '@/router';

export const http = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		'X-Requested-With': 'XMLHttpRequest'
	},
});

http.interceptors.request.use(
	(request) => {
		let accessToken = store.getters['user/accessToken'] || null;
		if (accessToken) {
			request.headers = Object.assign({
				Authorization: `Bearer ${accessToken}`
			}, request.headers);
		}
		return request;
	},
	(error) => {
		return Promise.reject(error);
	}
);

http.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {

		// Handle authentication error
		if(error.response.status === 401) {
			// Log user out to remove the currently saved auth token
			store.dispatch('user/logout');
			// Send user to the login page
			router.push('/login');
		}

		return Promise.reject(error);
	}
);

export const install = function(Vue) {
	Vue.prototype.$http = http;
};

export default {
	install,
}
