<template>
	<div class="flex h-full overflow-hidden relative" style="border-radius: 4px;">
		<div class="flex flex-1 items-center justify-center overflow-hidden px-4" ref="container">
			<div v-if="error">
				<div class="text-center text-white my-4">
					Couldn't load image
				</div>
			</div>
			<img
				v-show="!error && !loading"
				ref="image"
				:src="imageSrc"
				@load="imageLoaded"
				@error="imageError"
			/>
			<!-- <video
				v-show="!error && !loading"
				ref="video"
				class="video-modal__video"
				:style="{opacity: (error || loading ? 0 : 1)}"
				:src="videoSrc"
				controls
				playsinline
				autoplay
				@load="imageLoaded"
				@error="imageError"
			/> -->
		</div>
	</div>
</template>

<script>
import fit from 'fit.js';

export default {
	name: 'ImageModal',
	components: {
	},
	props: {
		media: {},
	},
	data() {
		return {
			fitWatcher: null,
			loading: true,
			error: false,
			errorCount: 0,
		}
	},
	computed: {
		imageSrc() {
			if(this.src) {
				return this.src;
			}
			if(this.media) {
				return this.$store.state.media.media[this.media.id]?.url;
			}
			return null;
		},
	},
	methods: {
		close() {
			this.$parent.close();
		},
		imageLoaded() {
			this.loading = false;
			this.errorCount = 0;

			this.$nextTick(() => {
				this.fitWatcher = fit(this.$refs.image, this.$refs.container, { watch: true, apply: true });
			});
		},
		async imageError() {
			// An image error is most likely the signed url expiring, so fetch the media again
			// which will have a new signed url

			this.errorCount += 1;

			// If video is repeatedly not working, stop checking otherwise we will get stuck in a loop
			if(this.errorCount >= 3) {
				this.error = true;
				return;
			}
			await this.fetchMedia();
		},
		async fetchMedia() {
			this.loading = true;
			try {
				await this.$store.dispatch('media/fetchMediaDebounced', { mediaId: this.media.id });
			} catch(e) {
				this.error = true;
			}
		},
	},
	mounted() {

		// if imageSrc is empty that means we havent fetched this media before, so fetch it
		if(!this.imageSrc) {
			this.fetchMedia();
		}

	},
	beforeDestroy() {
		if(this.fitWatcher) {
			this.fitWatcher.off();
		}
	},
};
</script>

<style lang="scss">
.video-modal {
	&__video {
		opacity: 0;
	}
	&__close-button {
		//opacity: 0;
		--padding-start: 6px;
		--padding-end: 6px;
	}
	&--loaded {
		.video-modal__video, .video-modal__close-button {
			animation: fadeInVideo .5s ease forwards;
		}
	}
	/* &--error {
		.video-modal__close-button {
			animation: fadeInVideo .5s ease forwards;
		}
	} */
	@keyframes fadeInVideo {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
}
</style>
