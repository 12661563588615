<template>
	<div class="main-header font-heading" :style="mainHeaderStyle">
		<container class="main-header__inner">
			<div class="main-header__left">
				<logo />
			</div>
			<div class="main-header__center">
				<main-menu :user-role="$store.getters['user/role']" />
			</div>
			<div class="main-header__right">
				<secondary-menu :user-role="$store.getters['user/role']" />
			</div>
		</container>
	</div>
</template>

<script>
import Container from '@/components/Container.vue';
import Logo from '@/components/symbols/Logo.vue';
import MainMenu from '@/components/ui/MainMenu.vue';
import SecondaryMenu from '@/components/ui/SecondaryMenu.vue';

export default {
	components: {
		Container,
		Logo,
		MainMenu,
		SecondaryMenu,
	},
	computed: {
		mainHeaderStyle() {
			if(this.$store.state.user.inApp) {
				// Menu should be hidden when in app, but we can't hide it because we need to be able to toggle
				// the dropdown menu that is within the menu, so instead we just give it a height of 0
				return {
					height: '0px',
					'overflow': 'hidden',
				};
			}
			return {};
		},
	},
}
</script>

<style lang="scss">
@import '@/assets/buefy.scss';

.main-header {
	position: relative;
	z-index: 1;
	&:after {
		content: "";
		display: block;
		background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
		//background: linear-gradient(90deg, rgba(0,141,54,1) 0%, rgba(17,150,110,1) 19%, rgba(53,168,224,1) 100%);
		position: absolute;
		left: 0;
		top: 100%;
		right: 0;
		height: 4px;;
	}
	&__inner {
		display: flex;
		align-items: center;
		width: 100%;
		min-height: 64px;
	}
	&__left {
		margin-right: auto;
	}
	&__right {
		margin-left: auto;
	}
	&__center {
		margin-left: auto;
		margin-right: auto;
		display: none;
		@screen xl {
			display: block;
		}
	}
}
</style>
