<template>
	<container class="mt-5">
		<b-breadcrumb
            size="is-small"
        >
            <b-breadcrumb-item
				tag='router-link'
				v-for="(crumb, index) in crumbs"
				:key="`${crumb.text}_${index}`"
				:to="crumb.to"
				:active="index === crumbs.length - 1"
			>{{crumb.text}}</b-breadcrumb-item>
        </b-breadcrumb>
	</container>
</template>

<script>
import Container from '@/components/Container.vue';

export default {
	name: 'Breadcrumbs',
	components: {
		Container,
	},
	computed: {
		crumbs() {
			const crumbs = [
				{
					text: 'Home',
					to: '/',
				},
			];

			if (this.$route.meta.breadcrumbs) {
				const routeCrumbs = this.$route.meta.breadcrumbs();
				if(Array.isArray(routeCrumbs)) {
					crumbs.push(...routeCrumbs);
				} else {
					crumbs.push(routeCrumbs);
				}
			}

			return crumbs;
		},
	},
};
</script>
