import { http } from '@/plugins/http';
import i18n from "@/plugins/i18n";

export const namespaced = true;

const defaults = {
	user: null,
	token: null,
	inApp: false,
};

export const state = () => {
	const userData = localStorage.getItem('user');
	const tokenData = localStorage.getItem('token');
	const inAppData = localStorage.getItem('inApp');
	const user = userData ? JSON.parse(userData) : null;
	const token = tokenData ? JSON.parse(tokenData) : null;
	const inApp = inAppData ? JSON.parse(inAppData) : false;

	return {
		...defaults,
		user,
		token,
		inApp,
	};
};

export const getters = {
	home(state) {
		if(!getters.authenticated || !state.user) {
			return '/login';
		}

		if(state.user.roles.includes('rockit-admin')) {
			return `/${i18n.t('clubs.slug')}`;
		}
		if(state.user.roles.includes('club-owner')) {

			const club = state.user.club_owner_profile.clubs[0];
			if(club) {
				return `/${i18n.t('clubs.slug')}/${club.id}`;
			}

		}
		if(state.user.roles.includes('coach')) {

			const teamAbility = state.user.abilities.find((ability) => {
				return ability.entity_type === 'team' && ability.name === 'manage';
			});

			if(!teamAbility) {
				return '/error/404';
			}

			return `/teams/${teamAbility.entity_id}/players`;
		}

		if(state.user.roles.includes('parent') || state.user.roles.includes('player')) {
			return '/non-admin-user';
		}

		return '/set-up-account';
	},
	mainMenuItems(state, getters) {
		if(!getters.authenticated) {
			return [];
		}

		if(state.user.roles.includes('rockit-admin')) {
			return [
				{
					label: i18n.t('navigation.mainMenu.players'),
					key: 'players',
					children: [
						{
							url: `/${i18n.t('clubs.slug')}`,
							key: i18n.t('clubs.slug'),
							label: i18n.t('navigation.mainMenu.clubs'),
						},
						{
							url: `/${i18n.t('individuals.slug')}`,
							key: i18n.t('individuals.slug'),
							label: i18n.t('navigation.mainMenu.individuals'),
						},
					],
				},

				// {
				// 	url: `/${i18n.t('clubs.slug')}`,
				// 	key: i18n.t('clubs.slug'),
				// 	label: i18n.t('navigation.mainMenu.clubs'),
				// },
				// {
				// 	url: `/${i18n.t('individuals.slug')}`,
				// 	key: i18n.t('individuals.slug'),
				// 	label: i18n.t('navigation.mainMenu.individuals'),
				// },
				{
					label: i18n.t('navigation.mainMenu.content'),
					key: 'content',
					children: [
						{
							url: `/${i18n.t('seasons.slug')}`,
							key: i18n.t('seasons.slug'),
							label: i18n.t('navigation.mainMenu.seasons'),
						},
						{
							url: `/${i18n.t('challenges.slug')}`,
							key: i18n.t('challenges.slug'),
							label: i18n.t('navigation.mainMenu.challenges'),
						},
						{
							url: `/${i18n.t('missions.slug')}`,
							key: i18n.t('missions.slug'),
							label: i18n.t('navigation.mainMenu.missions'),
						},
						{
							url: `/${i18n.t('announcements.slug')}`,
							key: i18n.t('announcements.slug'),
							label: i18n.t('navigation.mainMenu.announcements'),
						},
						{
							url: '/faqs',
							key: 'faqs',
							label: 'FAQs',
						},
					],
				},
				{
					url: '/tokens',
					key: 'tokens',
					label: i18n.t('navigation.mainMenu.tokens'),
				},
				{
					url: '/moderation',
					key: 'moderation',
					label: i18n.t('navigation.mainMenu.moderation'),
				},
				{
					url: '/settings',
					key: 'settings',
					label: i18n.t('navigation.mainMenu.settings'),
				}
			];
		}
		if(state.user.roles.includes('coach')) {
			return [
				{
					url: `/${i18n.t('team.slug')}/${getters.teamId}/${i18n.t('teamPlayers.slug')}`,
					key: `${i18n.t('team.slug')}_${i18n.t('teamPlayers.slug')}`,
					label: 'Players',
				},
				{
					url: `/${i18n.t('hangouts.slug')}`,
					key: i18n.t('hangouts.slug'),
					label: 'Hangouts',
				},
			];
		}
	},
	clubId(state) {
		if(!state.user || !state.user.abilities) {
			return null;
		}
		const clubOwnershipAbility = state.user.abilities.find((ability) => {
			return ability.entity_type === 'club' && ability.name === 'manage';
		});

		if(!clubOwnershipAbility) {
			return null;
		}

		return clubOwnershipAbility.entity_id;
	},
	teamId(state) {
		const teamAbility = state.user.abilities.find((ability) => {
			return ability.entity_type === 'team' && ability.name === 'manage';
		});
		if(teamAbility) {
			return teamAbility.entity_id;
		}
	},
	role(state) {
		if(!state.user || !state.user.roles) {
			return [];
		}
		return state.user.roles[0];
	},
	is: (state) => (role) => {
		return state.user.roles.includes(role);
	},
	authenticated(state) {
		return state.token !== null && state.user !== null;
	},
	accessToken(state) {
		if(!state.token) {
			return;
		}
		return state.token.access_token;
	},
	name(state) {
		if(!state.user) {
			return null;
		}
		return state.user.first_name + ' ' + state.user.last_name;
	},
	profilePicture(state) {
		if(!state.user || !state.user.profile_picture) {
			return null;
		}
		return state.user.profile_picture;
	},
	isAdmin(state) {
		if(!state.user || !state.user.roles) {
			return false;
		}
		return state.user.roles.includes('rockit-admin');
	}
};

export const mutations = {
	setUser(state, data) {
		state.user = data;
		localStorage.setItem('user', JSON.stringify(state.user));
	},
	setToken(state, data) {
		state.token = data;
		localStorage.setItem('token', JSON.stringify(state.token));
	},
	logout(state) {
		state.token = null;
		state.user = null;
		state.inApp = false;
		localStorage.removeItem('user');
		localStorage.removeItem('token');
	},
	setInApp(state, data) {
		state.inApp = data;
		localStorage.setItem('inApp', JSON.stringify(state.inApp));
	}
};

export const actions = {
	async login({ commit, dispatch }, formData) {
		try {
			const tokenResponse = await http.post('/login', formData);

			commit('setToken', tokenResponse.data.data);

			await dispatch('fetchUser');
		} catch(e) {
			return Promise.reject(e);
		}
	},
	async loginWithExistingToken({ commit, dispatch }, tokenData) {
		try {
			commit('setToken', tokenData);
			await dispatch('fetchUser');
		} catch(e) {
			return Promise.reject(e);
		}
	},
	async fetchUser({ commit }) {

		try {
			const response = await http.get('/me');
			commit('setUser', response.data.data.user);
		} catch(e) {
			return Promise.reject(e);
		}

	},
	async logout({ commit }) {
		commit('logout');
	},
};
