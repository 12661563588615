import Vue from 'vue';
import { http } from '@/plugins/http';

export const namespaced = true;

const defaults = {
	stats: {},
};

export const state = () => {
	return { 
		...defaults, 
	};
};

export const getters = {
	
};

export const mutations = {
	setStats(state, stats) {
		Vue.set(state, 'stats', stats);
	},
};

export const actions = {
	async fetchStats({ commit }) {

		try {
			const response = await http.get(`/clubs/stats`);
			commit('setStats', response.data.data.stats);
		} catch(e) {
			return Promise.reject(e);
		}

	},
};