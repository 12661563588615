import Vue from 'vue';

export const bus = new Vue();

export const install = function(Vue){
	Vue.prototype.$bus = bus;
};

export default {
	install,
};