<template>
	<div v-if="$store.getters['user/authenticated']">
		<b-dropdown
			ref="smallBreakpointMenu"
			position="is-bottom-left"
			append-to-body
			aria-role="menu"
			trap-focus
			v-if="['xs', 'sm', 'md', 'lg'].includes($mq)"
		>
			<template #trigger>
				<button type="button" role="button" class="flex items-center">
					<div v-if="$store.getters['user/profilePicture']" class="mr-2 w-6 h-6 rounded-full overflow-hidden">
						<image-media ratio="1:1" :media="$store.getters['user/profilePicture']" media-conversion="avatar_small" />
					</div>
					<b-icon icon="bars" size="is-medium"></b-icon>
				</button>
			</template>

			<b-dropdown-item v-for="item in menuItems" :key="item.key" has-link aria-role="listitem">
				<router-link v-for="child in item.children" :key="`${item.key}_${child.key}`" :to="child.url">
					{{child.label}}
				</router-link>
				<router-link v-if="!item.children" :to="item.url">
					{{item.label}}
				</router-link>
			</b-dropdown-item>

			<b-dropdown-item v-if="!$store.state.user.inApp" class="border-t" aria-role="listitem" @click="editProfile">
				{{$t('navigation.secondaryMenu.editProfile')}}
			</b-dropdown-item>
			<b-dropdown-item v-if="!$store.state.user.inApp" aria-role="listitem" @click="changePassword">
				{{ $t('navigation.secondaryMenu.changePassword') }}
			</b-dropdown-item>
			<b-dropdown-item v-if="!$store.state.user.inApp"  aria-role="listitem" @click="logout">
				{{ $t('navigation.secondaryMenu.logout') }}
			</b-dropdown-item>
		</b-dropdown>
		<b-dropdown
			position="is-bottom-left"
			append-to-body
			aria-role="menu"
			trap-focus
			v-if="['xl', '2xl'].includes($mq)"
		>
			<template #trigger>
				<button type="button" role="button" class="flex items-center">
					<span v-if="$store.getters['user/name']">{{$store.getters['user/name']}}</span>
					<span v-else>Your account</span>
					<div class="ml-2">
						<div v-if="$store.getters['user/profilePicture']" class="w-6 h-6 rounded-full overflow-hidden">
							<image-media ratio="1:1" :media="$store.getters['user/profilePicture']" media-conversion="avatar_small" />
						</div>
						<div v-else>
							<b-icon icon="user-circle" size="is-medium"></b-icon>
						</div>
					</div>

				</button>
			</template>

			<b-dropdown-item aria-role="listitem" @click="editProfile">
				{{ $t('navigation.secondaryMenu.editProfile') }}
			</b-dropdown-item>
			<b-dropdown-item aria-role="listitem" @click="changePassword">
				{{ $t('navigation.secondaryMenu.changePassword') }}
			</b-dropdown-item>
			<b-dropdown-item aria-role="listitem" @click="logout">
				{{ $t('navigation.secondaryMenu.logout') }}
			</b-dropdown-item>
		</b-dropdown>
	</div>
</template>

<script>
import { bus } from '@/plugins/event-bus';
import UserProfileModal from '@/components/modals/UserProfileModal.vue';
import UserPasswordModal from '@/components/modals/UserPasswordModal.vue';
import ImageMedia from '@/components/media/ImageMedia.vue';

export default {
	props: {
		userRole: {},
	},
	components: {
		ImageMedia,
	},
	computed: {
		menuItems() {
			return this.$store.getters['user/mainMenuItems'];
		},
	},
	methods: {
		logout() {
			this.$store.dispatch('user/logout');
			this.$router.push('/login');
		},
		editProfile() {
			this.$buefy.modal.open({
				parent: this,
				component: UserProfileModal,
				hasModalCard: true,
				trapFocus: true,
				canCancel: ['escape', 'outside'],
				props: {
					teamId: this.teamId,
					teamMessageId: this.teamMessageId,
				},
			});
		},
		changePassword() {
			this.$buefy.modal.open({
				parent: this,
				component: UserPasswordModal,
				hasModalCard: true,
				trapFocus: true,
				canCancel: ['escape', 'outside'],
				props: {
					teamId: this.teamId,
					teamMessageId: this.teamMessageId,
				},
			});
		},
	},
	mounted() {
		bus.$on('window.onmessage', async (data) => {
			if (data && data === 'X_APP_TOGGLE_MENU') {
				this.$refs.smallBreakpointMenu.toggle();
			}
		});
	},
	beforeDestroy() {
		bus.$off('window.onmessage');
	},
}
</script>
