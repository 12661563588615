import { required, confirmed, length, email, min, max, size, mimes, dimensions } from 'vee-validate/dist/rules';
import { localize, extend, setInteractionMode } from 'vee-validate';
import i18n from '@/plugins/i18n';

// only validate when form is submitted
setInteractionMode('passive');

localize({
	en: {
		messages: {
		// generic rule messages...
		},
		fields: {
			password: {
				min: (name, data) => {
					return `Passwords must be at least ${data.length} characters`;
				}
			}
		}
	}
});


extend('required', {
	...required,
	message: 'This field is required'
});

extend('email', {
	...email,
	message: 'This field must be a valid email'
});

extend('confirmed', {
	...confirmed,
	message: 'This field confirmation does not match'
});

extend('length', {
	...length,
	message: 'This field must have 2 options'
});

extend('min', {
	...min,
});

extend('max', {
	...max,
	message: (name, data) => {
		return `This field can be no longer than ${data.length} characters`;
	},
});

extend('size', {
	...size,
	message: () => {
		return 'This file is too large';
	}
});

extend('mimes', {
	...mimes,
	message: (message, data) => {
		console.log(message, data)
		return 'Please make sure that the selected file is the correct format';
	}
});

extend('dimensions', {
	...dimensions,
	message: (message, data) => {
		return `This image must be ${data.width}x${data.height}px in size`;
	}
});

extend('challenge-attributes', {
	// Challenge must have at least one non-zero attribute
	validate(value) {
		// Get attributes from each group all together in one array
		const allAttributes = value.reduce((carry, attributeGroup) => {
			carry.push(...attributeGroup.attributes);
			return carry;
		},[]);
		// Filter out non-zero attributes
		const nonZeroAttributes = allAttributes.filter((attribute) => {
			return attribute.value > 0;
		});

		return nonZeroAttributes.length > 0;
	},
	message: i18n.t('validation.challengeAttributes.selectAtLeastOneAttribute'),
})
