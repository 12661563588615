<template>
	<ul class="main-menu">
		<li
			class="main-menu__item"
			v-for="item in menuItems"
			:key="`menu_item_${item.url ? item.url : item.key}`"
			:class="{
				'main-menu__item--active': (activeSection.substring(0, item.key.length) === item.key) || (item.children && item.children.some(child => activeSection.substring(0, child.key.length) === child.key)),
			}"
		>
			<b-dropdown v-if="item.children && item.children.length > 0">
				<template #trigger>
					<button type="button" class="main-menu__link">
						{{item.label}}
						<b-icon icon="chevron-down" />
					</button>
				</template>
				<b-dropdown-item has-link v-for="child in item.children" :key="`${item.key}_${child.key}`">
					<router-link :to="child.url" class="font-sans">{{child.label}}</router-link>
				</b-dropdown-item>
			</b-dropdown>
			<router-link v-else class="main-menu__link" :to="item.url">{{item.label}}</router-link>
		</li>

	</ul>
</template>

<script>
import { isNumeric } from '@/helpers/string';

export default {
	props: {
		userRole: {},
	},
	computed: {
		activeSection() {
			const urlParts = this.$route.fullPath.split('/');

			return urlParts.reduce((carry, part) => {
				if(!part || isNumeric(part)) {
					return carry;
				}

				return (carry ? `${carry}_` : '') + part;
			});
		},
		menuItems() {
			return this.$store.getters['user/mainMenuItems'];
		},
	},
}
</script>

<style lang="scss">
@import '@/assets/buefy.scss';

.main-menu {
	display: flex;
	&__item {
		position: relative;
		&--active {
			&:after {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 4px;
				background: $primary;
			}
		}
	}
	&__link {
		display: block;
		padding: 20px;
		@apply text-gray-500;
	}
}
</style>
