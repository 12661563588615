import Vue from 'vue';
import VueMq from 'vue-mq';

Vue.use(VueMq, {
	breakpoints: {
		'xs': 640,
		'sm': 769,
		'md': 1024,
		'lg': 1216,
		'xl': 1408,
		'2xl': Infinity,
	},
});