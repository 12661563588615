import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Buefy from 'buefy';
import './assets/buefy.scss'
import './assets/tailwind.scss'
import './assets/fonts.scss'
import '@fortawesome/fontawesome-free/css/all.css';
import './vee-validate';
import './vue-mq';
import Http from './plugins/http';
import i18n from './plugins/i18n';

Vue.config.productionTip = false

Vue.use(Buefy, {
	defaultIconPack: 'fa',
	defaultProgrammaticPromise: true,
});

Vue.use(Http);

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
