import { http } from '@/plugins/http';

export const namespaced = true;

const defaults = {
	mission: null,
};

export const state = () => {
	return { 
		...defaults, 
	};
};

export const getters = {
	name(state) {
		if(!state.mission) {
			return null;
		}
		return state.mission.name;
	},
	id(state) {
		if(!state.mission) {
			return null;
		}
		return state.mission.id;
	},
};

export const mutations = {
	setMission(state, mission) {
		state.mission = mission;
	},
};

export const actions = {
	async fetch({ commit }, missionId) {

		try {
			const response = await http.get(`/missions/${missionId}`);
			commit('setMission', response.data.data);
		} catch(e) {
			return Promise.reject(e);
		}
	},
};