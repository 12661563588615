import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import i18n from '@/plugins/i18n';

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: () => {
			if(!store.getters['user/authenticated']) {
				return '/login';
			}
			return store.getters['user/home'];
		},
	},
	{
		path: '/in-app',
		component: 	() => import('../views/auth/InApp.vue'),
		meta: {

		},
	},
	{
		path: '/invitation/:invitationCode',
		component: 	() => import('../views/auth/Invitation.vue'),
		meta: {
			hideMenu: true,
		},
	},
	{
		path: '/set-up-account',
		component: 	() => import('../views/auth/SetUpAccount.vue'),
	},
	{
		path: '/non-admin-user',
		component: 	() => import('../views/auth/NonAdminUser.vue'),
	},
	{
		path: '/login',
		name: 'login',
		component: 	() => import('../views/auth/Login.vue'),
		meta: {
			hideMenu: true,
			guest: true,
		},
	},
	{
		path: '/forgotten-password',
		name: 'forgottenPassword',
		component: 	() => import('../views/auth/ForgottenPassword.vue'),
		meta: {
			hideMenu: true,
			guest: true,
		},
	},
	{
		path: '/reset-password/:token/:email',
		name: 'resetPassword',
		component: 	() => import('../views/auth/ResetPassword.vue'),
		meta: {
			hideMenu: true,
		},
	},
	{
		path: `/${i18n.t('challenges.slug')}`,
		name: 'challenges',
		component: 	() => import('../views/admin/challenges/Challenges.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('challenges.heading'),
					to: { name: 'challenges' },
				};
			},
		},
	},
	{
		path: `/${i18n.t('missions.slug')}`,
		name: 'missions',
		component: 	() => import('../views/admin/missions/MissionsIndex.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('missions.heading'),
					to: { name: 'missions' },
				};
			},
		},
	},
	{
		path: `/${i18n.t('missions.slug')}/:missionId/${i18n.t('missionEntries.slug')}`,
		name: 'missionEntries',
		component: 	() => import('../views/admin/missions/MissionEntriesIndex.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return [
					{
						text: i18n.t('missions.heading'),
						to: { name: 'missions' },
					},
					{
						text: i18n.t('missionEntries.heading'),
						to: { name: 'missionEntries' },
					}
				];
			},
		},
	},
	{
		path: `/${i18n.t('settings.slug')}`,
		name: 'settings',
		component: 	() => import('../views/admin/settings/Settings.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('settings.heading'),
					to: { name: 'settings' },
				};
			},
		},
	},
	{
		path: `/${i18n.t('moderation.slug')}`,
		name: 'moderationIndex',
		component: 	() => import('../views/admin/moderation/ModerationIndex.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('moderation.heading'),
					to: { name: 'moderationIndex' },
				};
			},
		},
	},
	{
		path: `/${i18n.t('seasons.slug')}`,
		name: 'seasons',
		component: () => import('../views/admin/seasons/SeasonsIndex.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('seasons.heading'),
					to: { name: 'seasons' },
				};
			},
		},
	},
	{
		path: `/${i18n.t('seasons.slug')}/:id`,
		name: 'season',
		component: 	() => import('../views/admin/seasons/SeasonsEdit.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return [
					{
						text: i18n.t('seasons.heading'),
						to: { name: 'seasons' },
					},
					{
						text: i18n.t('season.heading'),
						to: { name: 'season', params: { id: router.currentRoute.params.id } },
					}
				];
			},
		},
	},
	{
		path: `/${i18n.t('clubs.slug')}`,
		component: 	() => import('../views/admin/clubs/Clubs.vue'),
		name: 'clubs',
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('clubs.pageTitle'),
					to: { name: 'clubs' },
				};
			},
		},
	},
	{
		path: `/${i18n.t('clubs.slug')}/:clubId`,
		component: 	() => import('../views/admin/clubs/Club.vue'),
		name: 'club',
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return [
					{
						text: i18n.t('clubs.pageTitle'),
						to: { name: 'clubs' },
					},
					{
						text: i18n.t('club.breadcrumb'),
						to: { name: 'clubs' },
					}
				];
			},
		},
	},
	{
		path: `/${i18n.t('clubs.slug')}/:clubId/theme`,
		component: 	() => import('../views/admin/clubs/ClubTheme.vue'),
		name: 'club-theme',
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return [
					{
						text: i18n.t('clubs.pageTitle'),
						to: { name: 'clubs' },
					},
					{
						text: i18n.t('clubTheme.breadcrumb'),
						to: { name: 'club-theme', params: { clubId: router.currentRoute.params.clubId } },
					}
				];
			},
		},
	},
	{
		path: `/${i18n.t('individuals.slug')}`,
		component: 	() => import('../views/admin/individuals/Individuals.vue'),
		name: 'individuals',
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('individuals.heading'),
					to: { name: 'individuals' },
				};
			},
		},
	},
	{
		path: `/${i18n.t('team.slug')}/:teamId`,
		component: 	() => import('../views/admin/teams/TeamBase.vue'),
		children: [
			{
				path: '/',
				redirect(to) {
					return { name: 'teamPlayers', params: { teamId: to.params.teamId } };
				},
				name: 'teamHome',
			},
			{
				path: `${i18n.t('teamPlayers.slug')}`,
				component: 	() => import('../views/admin/teams/TeamIndex.vue'),
				name: 'teamPlayers',
				meta: {
					requiresAuth: true,
					breadcrumbs() {
						return [
							{
								text: i18n.t('clubs.pageTitle'),
								to: { name: 'clubs' },
							},
							{
								text: i18n.t('club.breadcrumb'),
								to: { name: 'club', params: { clubId: store.state.team?.team?.club?.id } },
							},
							{
								text: i18n.t('teamPlayers.breadcrumb'),
								to: { name: 'teamPlayers' },
							}
						];
					},
				},
			},
		],
	},
	{
		path: `/${i18n.t('faqs.slug')}`,
		name: 'faqs',
		component: 	() => import('../views/admin/faqs/FaqsIndex.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('faqs.heading'),
					to: { name: 'faqs' },
				};
			},
		},
	},
	{
		path: `/${i18n.t('announcements.slug')}`,
		name: 'announcements',
		component: 	() => import('../views/admin/announcements/AnnouncementsIndex.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('announcements.heading'),
					to: { name: 'announcements' },
				};
			},
		},
	},
	{
		path: `/${i18n.t('faqs.slug')}/:faqCategoryId/${i18n.t('faqItems.slug')}`,
		name: 'faqItems',
		component: 	() => import('../views/admin/faqs/FaqItemsIndex.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return [
					{
						text: i18n.t('faqs.heading'),
						to: { name: 'faqs' },
					},
					{
						text: i18n.t('faqItems.heading'),
						to: { name: 'faqItems', params: { faqCategoryId: router.currentRoute.params.faqCategoryId } },
					},
				];
			},
		},
	},
	// Media library for player
	{
		path: i18n.t('playerMediaLibrary.slug'),
		component: 	() => import('../views/admin/players/PlayerMediaLibrary.vue'),
		name: 'playerMediaLibrary',
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('playerMediaLibrary.breadcrumb'),
					to: { name: 'playerMediaLibrary' },
				};
			},
		},
	},
	// Timetable for player
	{
		path: `/${i18n.t('playerTimetable.slug')}`,
		component: 	() => import('../views/admin/players/PlayerTimetable.vue'),
		name: 'playerTimetable',
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('playerTimetable.breadcrumb'),
					to: { name: 'playerTimetable' },
				};
			},
		},
	},
	{
		path: `/${i18n.t('hangouts.slug')}`,
		name: 'hangouts',
		component: 	() => import('../views/admin/hangouts/HangoutsIndex.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('hangouts.heading'),
					to: { name: 'hangouts' },
				};
			},
		},
	},
	{
		path: `/${i18n.t('tokens.slug')}`,
		name: 'tokens',
		component: 	() => import('../views/admin/tokens/TokensIndex.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return {
					text: i18n.t('tokens.heading'),
					to: { name: 'tokens' },
				};
			},
		},
	},
	{
		path: `/${i18n.t('hangouts.slug')}/:hangoutId/${i18n.t('hangoutParticipants.slug')}`,
		name: 'hangoutParticipants',
		component: 	() => import('../views/admin/hangouts/HangoutParticipantsIndex.vue'),
		meta: {
			requiresAuth: true,
			breadcrumbs() {
				return [
					{
						text: i18n.t('hangouts.heading'),
						to: { name: 'hangouts' },
					},
					{
						text: i18n.t('hangoutParticipants.breadcrumb'),
						to: { name: 'hangoutParticipants', params: { hangoutId: router.currentRoute.params.hangoutId } },
					}
				];
			},
		},
	},

	{
		path: '/error/:code',
		name: 'error',
		component: 	() => import('../views/Error.vue'),
	},
	// Catch any routes that haven't been matches and redirect to 404 page
	{
		path: '*',
		redirect: '/error/404',
	},
];

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {

	if(to.matched.some(record => record.meta.requiresAuth)) {
		if(!store.getters['user/authenticated']) {
			next({
				path: '/login',
				params: { nextUrl: to.fullPath }
			});
		} else {
			next();
		}
	} else if(to.matched.some(record => record.meta.guest)) {
		if(!store.getters['user/authenticated']) {
			next();
		}
		else{
			next(store.getters['user/home']);
		}
	} else {
		next();
	}

});

export default router
