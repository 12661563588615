<template>
	<div id="app">
		<main-header v-if="showMenu" />

		<breadcrumbs v-if="userIsAdmin" />

		<router-view/>
	</div>
</template>

<script>
import { bus } from '@/plugins/event-bus';
import MainHeader from '@/components/MainHeader.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
	components: {
		MainHeader,
		Breadcrumbs,
	},
	computed: {
		showMenu() {
			return this.$route.meta.hideMenu !== true;
		},
		userIsAdmin() {
			return this.$store.getters['user/isAdmin'];
		},
	},
	async created() {
		await this.$store.dispatch('content/init');
	},
	mounted() {
		window.onmessage = async (e) => {
			bus.$emit('window.onmessage', e.data);
		};
	},
}
</script>
