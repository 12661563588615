<template>
	<div class="image-media relative" :class="{
		'image-media--loading': loading,
		'image-media--loaded': !loading,
		'bg-transparent': transparentBackground,
	}" :style="style">
		<img class="image-media__image absolute inset-0" :style="{opacity: (error || loading ? 0 : 1)}" :src="imageSrc" @load="imageLoaded" @error="imageError" />
		<div v-if="$slots.overlay && (!hideOverlayWhenLoading || (hideOverlayWhenLoading && !loading))" class="absolute inset-0 flex items-center justify-center text-8xl text-white opacity-50">
			<slot name="overlay" />
		</div>
		<div v-if="error" class="absolute inset-0 flex items-center justify-center">
			<div>
				Couldn't load image
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ImageMedia',
	props: {
		media: {},
		mediaConversion: {},
		ratio: {
			type: String,
			required: true,
		},
		hideOverlayWhenLoading: {
			type: Boolean,
			default: false,
		},
		transparentBackground: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: true,
			error: false,
			errorCount: 0,
		};
	},
	computed: {
		ratioX() {
			return this.ratio.split(':')[0];
		},
		ratioY() {
			return this.ratio.split(':')[1];
		},
		style() {
			return {
				paddingTop: `calc(${this.ratioY} / ${this.ratioX} * 100%)`,
			};
		},
		imageSrc() {
			if(this.media) {
				if(this.mediaConversion) {
					return this.$store.state.media.media[this.media.id]?.conversion_urls[this.mediaConversion];
				}
				return this.$store.state.media.media[this.media.id.toString()]?.url;
			}
			return '';
		},
	},
	methods: {
		imageLoaded() {
			this.loading = false;
			this.errorCount = 0;
			this.error = false;
			this.$emit('loaded');
		},
		async imageError() {
			this.errorCount += 1;

			// If image is repeatedly not working, stop checking otherwise we will get stuck in a loop
			if(this.errorCount >= 3) {
				this.error = true;
				return;
			}

			await this.fetchMedia();
		},
		async fetchMedia() {
			this.loading = true;
			try {
				await this.$store.dispatch('media/fetchMediaDebounced', { mediaId: this.media.id });
			} catch(e) {
				this.error = true;
				this.loading = false;
			}
		},
	},
	watch: {
		media() {
			if(!this.imageSrc) {
				this.fetchMedia();
			}
		},
	},
	mounted() {
		// if imageSrc is empty that means we havent fetched this media before, so fetch it
		if(!this.imageSrc) {
			this.fetchMedia();
		}
	},
}
</script>

<style lang="scss">
.image-media {
	background: #CCC;
	overflow: hidden;
	&__image {
		opacity: 0;
		width: 100%;
		height: auto;
	}
	&--loading {
		background: linear-gradient(270deg, #d6d6d6, #b1b1b1);
		background-size: 400% 400%;
		animation: AnimatedLoadingGradient 1s ease infinite;
	}
	@keyframes AnimatedLoadingGradient {
		0%{background-position:0% 50%}
		50%{background-position:100% 50%}
		100%{background-position:0% 50%}
	}
}
</style>
