import { http } from '@/plugins/http';

export const namespaced = true;

const defaults = {
	club: null,
};

export const state = () => {
	return { 
		...defaults, 
	};
};

export const getters = {
	name(state) {
		if(!state.club) {
			return null;
		}
		return state.club.name;
	},
	id(state) {
		if(!state.club) {
			return null;
		}
		return state.club.id;
	},
};

export const mutations = {
	setClub(state, club) {
		state.club = club;
	},
};

export const actions = {
	async fetch({ commit }, clubId) {

		try {
			const response = await http.get(`/clubs/${clubId}`);
			commit('setClub', response.data.data);
		} catch(e) {
			return Promise.reject(e);
		}
	},
};