import { http } from '@/plugins/http';

export const namespaced = true;

const defaults = {
	team: null,
};

export const state = () => {
	return { 
		...defaults, 
	};
};

export const getters = {
	name(state) {
		if(!state.team) {
			return null;
		}
		return state.team.name;
	},
	id(state) {
		if(!state.team) {
			return null;
		}
		return state.team.id;
	},
};

export const mutations = {
	setTeam(state, team) {
		state.team = team;
	},
};

export const actions = {
	async fetch({ commit }, teamId) {

		try {
			const response = await http.get(`/teams/${teamId}`);
			commit('setTeam', response.data.data);
		} catch(e) {
			return Promise.reject(e);
		}

	},
};