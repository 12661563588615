import { merge } from 'lodash';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import langEn from '@/lang/en.json';
import themeEn from '@/lang/theme/en.json';

Vue.use(VueI18n); // < This must be called before instantiating VueI18n

const messages = {
	en: merge(langEn, themeEn),
};

const i18n = new VueI18n({
	locale: 'en',
	messages,
});

export default i18n;
